import { Box, Text } from '@everlywell/ui-kit';
import useTelehealthMedications from 'common/hooks/useTelehealthMedications';
import { MultiSelectAutoCompleteProps } from 'components/MultiSelectAutoComplete/MultiSelectAutoComplete';
import React from 'react';

import { FormBuilderField } from '../../utils/types';
import { AutoCompleteField } from '../AutoCompleteField/AutoCompleteField';

type MedicationsAutoCompleteFieldProps = FormBuilderField &
  MultiSelectAutoCompleteProps;

export const MedicationsAutoCompleteField = (
  props: MedicationsAutoCompleteFieldProps,
) => {
  const { medications: data, ...rest } = useTelehealthMedications();

  return (
    <Box as="fieldset" display="flex" flexDirection="column" gap="3">
      <Text as="label" color="tints.black" fontFamily="heading" fontSize="4xl">
        {props.label ?? 'Medications'}
      </Text>
      <Box width="340px">
        <AutoCompleteField
          {...rest}
          {...props}
          data={data}
          placeholder="Search medications"
          notFoundMessage={props.notFoundMessage || 'No medications found'}
        />
      </Box>
    </Box>
  );
};
