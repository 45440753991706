// Validation function feet field and inches field
export const INVALID_NUMBER_ERROR = 'Please enter a valid number';
export const isNotNegative = (value: string) =>
  parseInt(value) < 0 ? INVALID_NUMBER_ERROR : true;

type ValidateMinOrMaxNumberArgs = {
  value: number;
  errorMessage?: string;
};

type ValidateMinValueArgs = ValidateMinOrMaxNumberArgs & { minValue: number };
type ValidateMaxNumberArgs = ValidateMinOrMaxNumberArgs & { maxValue: number };

export const validateMinValue = ({
  value,
  minValue,
  errorMessage = INVALID_NUMBER_ERROR,
}: ValidateMinValueArgs) => (value < minValue ? errorMessage : true);

export const validateMaxValue = ({
  value,
  maxValue,
  errorMessage = INVALID_NUMBER_ERROR,
}: ValidateMaxNumberArgs) => (value > maxValue ? errorMessage : true);
