import { size, H4, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${size.sm}px;
  flex-wrap: wrap;
`;

export const FirstLine = styled(H4)`
  font-weight: ${typography.weight.xbold};
  margin: 0 0 ${size.xs2}px;
`;

export const SecondLine = styled.div`
  ${typography.bodyTextSmall};
`;

export const EditButton = styled.button`
  ${typography.buttonText};
  display: flex;
  height: fit-content;
  align-items: center;
  cursor: pointer;
`;

export const EditIconWrapper = styled.div`
  margin-right: 6px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
