import { Box, Text } from '@everlywell/ui-kit';
import useAllergies from 'common/hooks/useAllergies';
import { MultiSelectAutoCompleteProps } from 'components/MultiSelectAutoComplete';
import React from 'react';

import { FormBuilderField } from '../../utils/types';
import { AutoCompleteField } from '../AutoCompleteField/AutoCompleteField';

type AllergiesAutoCompleteFieldProps = FormBuilderField &
  MultiSelectAutoCompleteProps;
export const AllergiesAutoCompleteField = (
  props: AllergiesAutoCompleteFieldProps,
) => {
  const { allergies: data, ...rest } = useAllergies();
  return (
    <Box as="fieldset" display="flex" flexDirection="column" gap="3">
      <Text as="label" color="tints.black" fontFamily="heading" fontSize="4xl">
        {props.label ?? 'Allergies'}
      </Text>
      <Box width="340px">
        <AutoCompleteField
          {...rest}
          {...props}
          data={data}
          placeholder="Search allergies"
          notFoundMessage={props.notFoundMessage || 'No allergies found'}
        />
      </Box>
    </Box>
  );
};
