import {
  RadioButton as BaseRadioButton,
  Button,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import { HEADER_HEIGHT } from 'common/utils/constants';
import Grid from 'components/Grid';
import styled from 'styled-components';

import { S } from '../Payment';

const AddNewPaymentBtn = styled(Button)`
  padding: ${size.sm}px;
`;

const Container = styled(S.Container)`
  ${mediaQueries.forTabletHorizontalUp} {
    min-width: 500px;
  }
`;

const CardIcon = styled.img`
  display: inline;
  width: ${size.xl4}px;
  object-fit: contain;
  margin-left: ${size.xs3}px;
  margin-right: ${size.xs1}px;
`;

const Label = styled.div`
  ${typography.bodyText}
`;

const RadioButton = styled(BaseRadioButton)`
  margin-bottom: ${size.md}px;
`;

const HR = styled.hr`
  width: 100%;
  color: ${colors.gray4};
`;

const SubmitWrapper = styled(Grid.Item)`
  padding: 0;
  margin-top: calc(50svh - ${HEADER_HEIGHT}px);

  .floating-cta-footer {
    margin-top: ${size.md}px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: 45lvh;
  }
`;

export * from '../Payment/Payment.styles';

export {
  AddNewPaymentBtn,
  CardIcon,
  Container,
  HR,
  Label,
  RadioButton,
  SubmitWrapper,
};
