import { useIsMobileEmbedded } from 'common/hooks/useIsMobileEmbedded';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { userPlanId } from 'common/utils/userPlanId';
import AppStoreSection from 'components/AppStoreSection';
import MyEverlyPhoneWithHand from 'components/AppStoreSection/assets/MyEverlyPhoneWithHand.png';
import Grid from 'components/Grid';
import Layout from 'components/Layout';
import React, { useEffect } from 'react';

import AsyncSuccessIcon from '../../assets/async_confimation.svg';
import { useNativeIntakeContext } from '../../context/NativeIntakeContext';
import * as S from './AsyncConfirmation.styles';

/**
 * This page is the last step in the intake process after the user has completed the flow.
 */
function AsyncConfirmation() {
  const { currentProgramSlug } = useNativeIntakeContext();
  const { isMobileEmbedded } = useIsMobileEmbedded();
  userPlanId.removePlanId();

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        program: currentProgramSlug,
        page: 'Visit Confirmation',
      },
    });
  }, [currentProgramSlug]);

  const handleAnalytics = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.VIRTUAL_INTAKE.CONFIRMATION.GO_TO_DASHBOARD,
      },
    });
  };

  return (
    <Layout>
      <S.Container>
        <S.InnerContainer>
          <Grid.Container spacing={['md']}>
            <Grid.Item width={[1]} css={{ justifyContent: 'center' }}>
              <img src={AsyncSuccessIcon} alt="" style={{ margin: '0 auto' }} />
            </Grid.Item>

            <S.HeaderWrapper.Item width={[1]}>
              <S.H3 as="h1">Your care review has been submitted!</S.H3>
            </S.HeaderWrapper.Item>

            <Grid.Item width={[1]}>
              <S.Copy>We will email you when your care plan is ready.</S.Copy>
              <S.Copy>
                We may reach out if there is any health information we need to
                clarify.
              </S.Copy>
            </Grid.Item>
            {!isMobileEmbedded && (
              <Grid.Item width={['auto']}>
                <S.Button
                  appearance="primary"
                  href="/dashboard"
                  onClick={handleAnalytics}
                >
                  Go to My Everly Portal
                </S.Button>
              </Grid.Item>
            )}
          </Grid.Container>
        </S.InnerContainer>
        {!isMobileEmbedded && (
          <Grid.Container spacing={['md']}>
            <Grid.Item width={['auto']}>
              <S.AppContainer>
                <AppStoreSection
                  title="Check your progress from anywhere"
                  description="Manage care, join your appointments, and track your progress right from the Everlywell app."
                  starRatings={4.9}
                  image={{
                    src: MyEverlyPhoneWithHand,
                    alt: 'My Everly Phone With Hand Image',
                  }}
                />
              </S.AppContainer>
            </Grid.Item>
          </Grid.Container>
        )}
      </S.Container>
    </Layout>
  );
}

export default AsyncConfirmation;
