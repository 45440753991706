import { Text } from '@everlywell/ui-kit';
import { Allergy, TelehealthMedicationV2 } from 'common/apis/telehealthApis';
import MultiSelectAutoComplete, {
  MultiSelectAutoCompleteProps,
} from 'components/MultiSelectAutoComplete/MultiSelectAutoComplete';
import {
  OptionType,
  OptionsCB,
} from 'components/MultiSelectAutoComplete/types';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { responseAutoCompleteParser } from '../../utils/parsers';
import { FormBuilderField } from '../../utils/types';

type AutoCompleteFieldProps = FormBuilderField &
  MultiSelectAutoCompleteProps & {
    search: (inputValue: string) => void;
    isError: boolean;
    isLoading: boolean;
    data: TelehealthMedicationV2[] | Allergy[];
    notFoundMessage: string;
  };

export const AutoCompleteField = (props: AutoCompleteFieldProps) => {
  const { isLoading, data, isError, search } = props;
  const fieldName = props.id;
  const { control, register, errors, watch } = useFormContext();
  const [hasError, setHasError] = useState(false);
  const callbackRef = useRef<OptionsCB | null>(null);

  const parsedData = responseAutoCompleteParser(data);

  useEffect(() => {
    if (isLoading) return;

    setHasError(isError);
  }, [isError, isLoading]);

  const promiseOptions = useCallback(
    (inputValue: string, callback: OptionsCB) => {
      search(inputValue);
      callbackRef.current = callback;
    },
    [search],
  );

  useEffect(() => {
    if (callbackRef.current && parsedData.length > 0) {
      callbackRef.current(parsedData);
    } else if (callbackRef.current && parsedData.length === 0 && !isLoading) {
      callbackRef.current([]);
    }
  }, [isLoading, parsedData]);

  const fieldValue = watch(`tmp-${fieldName}`);
  const hiddenValue =
    fieldValue?.map((item: OptionType) => item.value).join(',') ?? '';

  return (
    <>
      <Controller
        control={control}
        name={`tmp-${fieldName}`}
        defaultValue={[]}
        rules={{
          required: props.required && `Please select at least one ${fieldName}`,
        }}
        render={({ onChange, ref }) => (
          <MultiSelectAutoComplete
            name={`tmp-${fieldName}`}
            callbackLoadingOptions={promiseOptions}
            defaultOptions={parsedData}
            defaultValue={props.defaultValue}
            hasError={hasError}
            isLoading={isLoading}
            notFoundMessage={props.notFoundMessage}
            onChange={(val: OptionType[]) => {
              onChange(val);
            }}
            placeholder={props.placeholder || `Select a ${fieldName}`}
            ref={ref}
            setHasError={setHasError}
          />
        )}
      />
      {errors && (
        <Text fontSize="md" color="utility.warning">
          {errors[fieldName]?.message}
        </Text>
      )}
      <input
        type="hidden"
        name={fieldName}
        data-test="hidden-input"
        ref={register()}
        value={hiddenValue}
      />
    </>
  );
};
