import {
  Button as BaseButton,
  H3 as BaseH3,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import Grid from 'components/Grid';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  overflow: hidden;
  padding: 0 0 ${size.md}px;
  width: 100%;

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl2}px 0 ${size.md}px 0;
  }
`;

export const InnerContainer = styled.div`
  align-items: center;
  color: ${colors.teal4};
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 550px;
  padding: ${size.lg}px 0 ${size.xl5}px;
  text-align: center;
  width: 100%;

  ${mediaQueries.forPhoneOnly} {
    padding-right: ${size.md}px;
    padding-left: ${size.md}px;
  }
`;

export const Copy = styled.p`
  ${typography.bodyText}
  color: ${colors.teal4};

  &:last-of-type {
    margin-bottom: ${size.xs1}px;
  }

  ${mediaQueries.forDesktopUp} {
    margin: 0;
    &:last-of-type {
      margin-bottom: ${size.xl1}px;
    }
  }
`;

export const Button = styled(BaseButton)`
  width: calc(100% - 2 * ${size.md}px);
  margin: 0 ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    width: 100%;
    margin: 0 ${size.xs1}px 0;
  }
`;

export const AppContainer = styled.div`
  margin: auto;
  max-width: 1064px;
  padding-bottom: ${size.xl5}px;
  width: 100%;
`;

export const HeaderWrapper = {
  Item: styled(Grid.Item)`
    padding-bottom: 0;
    ${mediaQueries.forDesktopUp} {
      padding-bottom: ${Number(size.xs1) * 2}px;
    }
  `,
};

export const H3 = styled(BaseH3)`
  ${typography.h3Text};
  margin-bottom: 0;

  ${mediaQueries.forPhoneOnly} {
    margin-bottom: 0.67em;
  }
`;
